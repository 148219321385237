export default {
  init() {
    // JavaScript to be fired on the home page
    // Detect scroll, toggle class
    $(document).scroll(function() {
      var value = $(window).scrollTop();
     
      if ( value >= 180 ) {
        $('header.banner').removeClass('transparent-header');
      return;
      } else {
        $('header.banner').addClass('transparent-header');
      }
    });
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
