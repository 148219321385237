export default {
  init() {
    // JavaScript to be fired on all pages

    // stop dummy links
    $('a[href="#"]').click(function(e){
      e.preventDefault();
    });

    // Toggle Search
    $('#searchToggler').click(function(){
      $('.search-form').toggleClass('search-hide');
      if($('input[type="search"]').is(':focus')) {
        $('input[type="search"]').blur();
      } else {
        $('input[type="search"]').focus();
      }
    });

    $('#mobileSearchToggler').click(function(){
      $('input[type="search"]').focus();
    });

    // expand timelinw Block
    $('#timeline-more').on('click', function(){
      var timelineHeight = $('.timeline').css('height' + 1);
      $(this).fadeOut(400);
      $('.block-mask').removeClass('vh-fixed-95 fade-mask', 400).css('height', timelineHeight);
    });

    // Stop video in modal when closed
    $('.modal').on('hidden.bs.modal', function() {
       var memory = $(this).html();
       $(this).html(memory);
    });

    // Set offCanvas section
    var offCanvas = document.getElementById('offcanvasNavbar');

    // Show offCanvas
    offCanvas.addEventListener('show.bs.offcanvas', function () {
      $('.hamburger').addClass('is-active');
      $('.offcanvas-body.fade').addClass('show');
      $('html, body').addClass('overflow-y-hidden');
      if($('input[type="search"]').is(':visible')) {
        $(this).focus();
      }
    });
    offCanvas.addEventListener('shown.bs.offcanvas', function () {
      $('.offcanvas-body.animate__animated').addClass('animate__fadeIn');
    });
    // Hide offCanvas
    offCanvas.addEventListener('hide.bs.offcanvas', function () {
      $('.hamburger').removeClass('is-active');
      $('.offcanvas-body.animate__animated').removeClass('animate__fadeIn');
      $('html, body').removeClass('overflow-y-hidden');
    });

    function isInViewport(node) {
      // Am I visible? Height and Width are not explicitly necessary in visibility
      // detection, the bottom, right, top and left are the essential checks. If an
      // image is 0x0, it is technically not visible, so it should not be marked as
      // such. That is why either width or height have to be > 0.
      var rect = node.getBoundingClientRect()
      return (
        (rect.height > 0 || rect.width > 0) &&
        rect.bottom >= 0 &&
        rect.right >= 0 &&
        rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.left <= (window.innerWidth || document.documentElement.clientWidth)
      )
    }

    var width = $(window).width();
    $(window).resize(function() {
      width = $(window).width();
    });

    // $(window).on('scroll', function () {
    //   $('.type-companies header').each(function() {
    //     var initY = $(this).offset().top
    //     var height = $(this).height()
    //     //var endY = initY + $(this).height()
    //     // Check if the element is in the viewport.
    //     var scrolled = $(document).scrollTop();
    //     var visible = isInViewport(this)
    //     if(visible) {
    //       var diff = scrolled - initY
    //       var ratio = (diff / height) * 100
    //       $(this).css('background-position','center CALC(50% + ' + Math.floor((ratio * 3)) + 'px)')
    //       $(this).closest('.type-companies').addClass('reset-filter');
    //     } else {
    //       $(this).closest('.type-companies').removeClass('reset-filter');
    //     }
    //   });
    // });

    $(window).on('scroll', function () {
      $('.animate__animated.fadeIn').each(function() {
        var initY = $(this).offset().top
        var height = $(this).height()
        //var endY = initY + $(this).height()
        // Check if the element is in the viewport.
        var scrolled = $(document).scrollTop();
        var visible = isInViewport(this)
        if(visible) {
          var diff = scrolled - initY
          var ratio = (diff / height) * 100
          $(this).find('.carousel-item').css('background-position','center CALC(50% + ' + Math.floor((ratio * 3)) + 'px)')
          $(this).addClass('animate__fadeIn');
        }
      });
    });

    $(window).on('scroll', function () {
      if (width >= 991){
        var scrolled = $(document).scrollTop();

        $('.parallax-background').each(function() {
          var initY = $(this).offset().top
          var height = $(this).height()
          //var endY = initY + $(this).height()
          // Check if the element is in the viewport.
          var visible = isInViewport(this)
          if(visible) {
            var diff = scrolled - initY
            var ratio = (diff / height) * 100
            $(this).css('background-position','center CALC(50% + ' + Math.floor((ratio * 3)) + 'px)')
          }
        })

        $('.parallax-video').each(function() {
          var initY = $(this).offset().top
          var height = $(this).height()
          //var endY = initY + $(this).height()
          // Check if the element is in the viewport.
          var visible = isInViewport(this)
          if(visible) {
            var diff = scrolled - initY
            var ratio = (diff / height) * 100
            $(this).find('iframe').css('top','CALC(1px + ' + Math.floor((ratio * 3)) + 'px)')
          }
        })

        $('.parallax-carousel').each(function() {
          var initY = $(this).offset().top
          var height = $(this).height()
          //var endY = initY + $(this).height()
          // Check if the element is in the viewport.
          var visible = isInViewport(this)
          if(visible) {
            var diff = scrolled - initY
            var ratio = (diff / height) * 100
            $(this).find('.carousel-item').css('background-position','center CALC(50% + ' + Math.floor((ratio * 3)) + 'px)')
          }
        })
      }
    });

    // JavaScript to be fired on the home page
    // Detect scroll, toggle class
    $(document).scroll(function() {
      var value = $(window).scrollTop();

      if ( value >= 180 ) {
        $('header.banner').addClass('scrolled-header');
      return;
      } else {
        $('header.banner').removeClass('scrolled-header');
      }
    });

    // Show spyscroller element
    $(window).on('scroll', function () {
      if( window.scrollY > (window.innerHeight - 200) ) {
        $('#companies-list').addClass('right-reset');
      } else {
        $('#companies-list').removeClass('right-reset');
      }
    });

    // Scroll to element
    $('#companies-list a[href^="#"]').click(function() {
      var target = $(this.hash);
      if (target.length === 0) { target = $('div[id="' + this.hash.substr(1) + '"]');}
      if (target.length === 0) { target = $('html');}
      $('html, body').animate({ scrollTop: target.offset().top - 100 }, 300);
      return false;
    });

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    // Slick Slider Settings
    $('.post-slider').slick({
      infinite: false,
      slidesToShow: 2,
      slidetoScroll: 1,
      arrows: true,
      appendArrows: '.arrows',
      prevArrow: '<span class="prev-arrow slick-prev"><i class="has-white-color fas fa-long-arrow-alt-left fa-lg"></i></span>',
      nextArrow: '<span class="next-arrow slick-next"><i class="has-white-color fas fa-long-arrow-alt-right fa-lg"></i></span>',
      responsive: [
        {
          breakpoint: 1401,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });

    // initalise tooltips
    $('[data-bs-toggle=tooltip]').tooltip();

  },
};
